.Welcome-container {
margin-top: 10%;
display: flex;
flex-direction: row-reverse;
justify-content: space-around;
    
} 
.Welcome-img {
    border-radius: 25px;
    background-size: cover;
}

.Welcome-title {
  font-size: 32px;
}
.Welcome-title {
  overflow: hidden; 
  white-space: nowrap; 
 
}

.Welcome-mention {
  font-size: 22px;
  color: white;

}

.Welcome-about {
  font-size: 18px;
  
}
.Welcome-text {
margin-top: 5%;
width: 40%;
color: #fff;

}
.Welcome-button {
background-color: #182138; 
color: #fff; 
padding: 10px 20px; 
border: 2px solid #fff; 
border-radius: 5px; 
text-decoration: none; 
margin-top: 5%;
list-style-type: none;
font-weight: bold;
}
.Welcome-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: white;
  font-size: 24px;
  
}
.Welcome-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

/*RESPONSIVE */


@media (max-width: 768px) { 

.Welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.Welcome-img {
  max-width: 50%;
}

.Welcome-text {
  width: auto;
  text-decoration: none;
}


}