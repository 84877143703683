.footer-content {
    margin-top: 20%;
    color: white;
    background-color: #182138;
    text-align: center;
}
.hyper-lien{
    
    color: inherit; 
  }
  