body {
  
  max-width: 1280px; /* Choisissez la largeur maximale souhaitée en pixels */
  margin: 0 auto; /* Centre le contenu horizontalement */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #182138;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Error-container {
  text-align: center;
  margin-top: 25%;
  color: white;


}

.Error-container a{
  
  color: white;
}

.Mentioncolor {
  color: white;

  margin-top: 10%;
  
}