
  
  input[type="text"],
  textarea,
  input[type="email"] {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    margin-right: 0px;
    margin-left: 0px;
    resize: vertical;
  }
  
  label {
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    color: white;
    font-weight: bold;
  }
  
  input[type="submit"] {
    background-color: #1255a2;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #1872d9;
  }
  
  .container {
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
  
.title-form-main {
    color: white;
    text-align: center;
    font-weight: bold;
    margin-top: 10%;
    margin-bottom: 15%;

}

.title-form {
    font-size: 32px;
}

.fa-clipboard-question {
    font-size: 42px;}
.description-form {
    margin-top: 15%;
    font-size: 18px;
}

.politique-donnée {
    color: white;
    text-decoration-line: underline;
}

.infocall {
   text-decoration-line: underline;

}

.success-message {
  font-size: 32px;
  color: white;
  font-weight: bold;
  text-align: center;
}

