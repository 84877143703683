.Prestation-Section{
    color: white;
    margin-top: 15%;

    text-align: center;
}
.Prestation-Section h2{
    font-size: 32px;
}
.Prestation-Section h3{
    font-size: 22px;
    margin-top: 15%;
}     
.Prestation-Section p{
    font-size: 18px;
    
}
.sizeicicone{
    font-size: 45px;
   
}
.services-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5%;
    text-align: initial;
}
.elementor-service-cat {
    position: relative;
}
.info-prestation {
    margin-top: 15%;
    font-weight: bold;
}
.button-pos{
    margin-top: 10%;
    position: absolute ;
    background-color: transparent;
    border: 2.3px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    padding: 16px;
    cursor: pointer;
    outline: none;
    font-weight: bold;
}

.button-contact{
    margin-top: 5%;
    margin-bottom: 10%;
     background-color: transparent;
    border: 2.3px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    padding: 16px;
    cursor: pointer;
    outline: none;
    font-weight: bold;
}
.button-contact:hover {
    background-color: #ffffff;
    color: #182138;
    font-weight: bold;
}
.button-pos:hover {
    background-color: #ffffff;
    color: #182138;
    font-weight: bold;
}

/*RESPONSIVE */


@media (max-width: 768px) { 

    .button-pos{
        
        position: relative;
       
    }
    .services-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 5%;
        text-align: initial;
    }
}