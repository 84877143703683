.Header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    background-color: #182138;
    
  }
 
  .header-logo {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  
  
  .header-logo h1 {
    
    color: white;}
  
  nav {
    display: flex;
    align-items: center; 
    margin-right: 10px;
    list-style-type: none;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; 
    flex-direction: row; 
  }
  
  nav ul li {
    margin-left: 20px;
  }
  nav ul li a {
  color: inherit; 
  text-decoration: none; 
  font-weight: 800;
  
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3; 
  }

nav ul li a {
  color: white; 
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  
}


/* header.css */
.contact-button {
    background-color: #182138; /* Couleur de fond de votre arrière-plan */
    color: #fff; /* Couleur du texte */
    padding: 10px 20px; /* Rembourrage pour le bouton */
    border: 2px solid #fff; /* Bordure blanche de 2px */
    border-radius: 5px; /* Coins arrondis */
    text-decoration: none; /* Supprimez le soulignement du lien */
    transition: background-color 0.3s; /* Animation de transition pour la couleur de fond */
    font-weight: bold;
  }
  
  .contact-button:hover {
    background-color: #182138; /* Conservez la même couleur de fond au survol */
    color: #fff; /* Conservez la même couleur du texte au survol */
  }


  @media (max-width: 768px) {

.Header-container {
  font-size: 9px;
  display: flex;
  flex-direction: column;
  

  
}
.contact-button {
  display: none;

}}
.header-logo a {
  text-decoration: none;
}