.project-grid {
    margin-top: 15%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes égales */
    gap: 20px; /* Espacement entre les cartes */
    margin:40px;
  }
  
  .project-item {
    
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 25px; /* Coins arrondis */
    overflow: hidden;
    max-height: 300px; /* Hauteur maximale de 300 pixels */
    display: flex;
    flex-direction: column;
  }
  
  .project-image {
    width: 100%;
    height: auto;
    max-height: 200px; /* Hauteur maximale de l'image */
    object-fit: cover; /* Image s'adapte sans étirement */
    border-top-left-radius: 25px; /* Coin supérieur gauche arrondi */
    border-top-right-radius: 25px; /* Coin supérieur droit arrondi */
  }
  
  .project-title {
    padding: 10px;
    background-color: #182138; /* Fond noir */
    color: #fff; /* Texte blanc */
    font-weight: bold;
    text-align: center;
    border-bottom-left-radius: 25px; /* Coin inférieur gauche arrondi */
    border-bottom-right-radius: 25px; /* Coin inférieur droit arrondi */
    margin-top: auto; /* Place le titre en bas de la carte */
  }
.title-project-card {
    font-size: 32px;
    text-align: center;
    color:#fff;
    margin-top: 15%;
} 

/* Styles pour la modal */
.project-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.modalproject-content {
    background-color: #182138;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  width: 50%;
  font-weight: bold;
  text-decoration: none;

}

.lienproject {
    text-decoration: none;
    color:#fff;
}

.project-item:hover {
    transform: scale(1.05); /* Légère augmentation de taille au survol */
    transition: transform 0.3s ease-in-out; /* Animation de transition pour l'effet de survol */
  }
.modal-project-image {
width: 50%;
height: auto;
border-radius: 25px;
}

@media (max-width: 768px) { 

  .project-grid {
    margin-top: 15%;
    display: block;
    margin: 20px;
  }
 
}