
.Title-faq {
    text-align: center;
    color: white;
    margin-top: 20%;
    margin-bottom: 10%;
}

.sizelogo{
    font-size: 45px;
}

.accordion-main {

    margin: 40px;
}