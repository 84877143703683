.elementor-service {
  display: flex;
  margin-top: 15%;
  color: white;
  justify-content: space-around;


}
.elementor-service h3 {
  font-size: 22px;
}
.elementor-service p{
  font-size: 18px;
  
}
.elementor-service i {
font-size: 45px;
}
.elementor-service-cat {

  width:20% ;
}


/*RESPONSIVE */


@media (max-width: 768px) { 
.elementor-service {
  display: flex;
  flex-direction: column;
}
.elementor-service-cat{
  width: auto;
  text-align: center;
  margin: 10px;
}
}